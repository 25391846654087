import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import {
  deleteQueryParams,
  getAllQueryVar,
  urlAppendMultipleQueryVar,
} from '../../../../utils/query-vars';
import TableFilter from '../TableFilter';
import FilterService from '../service/filter-service';
import { FilterItem } from '../models/filter-item';
import { TabColour } from '../../pill/tab-color';
import { FilterIntEntityResponse } from '../models/filter-int-response';

const FormStatusFilter = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<FilterIntEntityResponse[]>([]);

  const load = async () => {
    const response = await FilterService.loadFormStatuses();
    if (response.success) {
      setItems(response.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onItemChanged = (selectedItems: FilterItem[]): void => {
    let url = '/';
    if (selectedItems.length === 0) {
      url = deleteQueryParams('statuses', 'page');
    } else {
      url = deleteQueryParams('statuses', 'page');
      url = urlAppendMultipleQueryVar(
        url,
        'statuses',
        selectedItems.map((i) => i.id.toString()),
      );
    }

    navigate(url);
  };

  useEffect(() => {
    load();
  }, []);

  let formStatusQuery = getAllQueryVar('statuses') ?? [];
  if (formStatusQuery.length === 1) {
    formStatusQuery = formStatusQuery[0].split(',');
  }

  const checkedItems: FilterItem[] = items.map((i) => ({
    id: i.id,
    name: i.name,
    color: TabColour.blue,
    selected: formStatusQuery.includes(i.id.toString()),
  }));

  return (
    <TableFilter
      filterName="statuses"
      items={checkedItems}
      title="Status"
      disabled={loading}
      onItemChanged={onItemChanged}
    />
  );
};

export default FormStatusFilter;
