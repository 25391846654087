import React from 'react';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { IconBin, IconClose, IconCloseCircle, IconDelete } from '../../icons';
import { ButtonRowMobile } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import {
  CloseIconButton,
  CombinedHeaderLeftRightIcon,
} from '../ModalContent.style';
import { PagedFormAssignment } from '../../../context-providers/forms/paged-form-assignment';
import { StageId } from '../../../context-providers/forms/form-workflows';

interface IStopReminders {
  onConfirmStop: () => void;
  formAssignment: PagedFormAssignment;
}

const StopReminders = ({ onConfirmStop, formAssignment }: IStopReminders) => {
  const closeModal = useCloseModal();

  const receipientName: string = (() => {
    switch (formAssignment.currentStageId) {
      case StageId.LEARNER:
        return formAssignment.learnerName;
      case StageId.EMPLOYER:
        return formAssignment.employerName;
      case StageId.PROVIDER:
        return formAssignment.trainingProviderName;
      default:
        return '';
    }
  })();

  return (
    <Stack stackMultiplier={2}>
      <CombinedHeaderLeftRightIcon>
        <div>
          <IconCloseCircle width={25} height={25} />
          <h2>Stop reminders</h2>
        </div>
        <CloseIconButton
          aria-label="{getModalTitle()}"
          data-qa="stopReminders-btn-cancel-icon"
          onKeyDown={(e) => e.key === 'Escape' && closeModal()}
          tabIndex={0}
          onClick={() => closeModal()}
        >
          <IconClose width={25} height={25} />
        </CloseIconButton>
      </CombinedHeaderLeftRightIcon>
      <p>
        Stop sending reminders to {receipientName} to complete{' '}
        {formAssignment.formName}?
      </p>
      <ButtonRowMobile>
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="secondary"
          dataQa="stopReminders-btn-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirmStop();
            closeModal();
          }}
          variant="primary"
          iconLeft={<IconCloseCircle />}
          dataQa="stopReminders-btn-submit"
        >
          Stop
        </Button>
      </ButtonRowMobile>
    </Stack>
  );
};
export default StopReminders;
