import React from 'react';
import AuthWrapper from '../../components/layout/AuthWrapper';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import ReviewFormsList from '../../components/organisms/review-forms-list/ReviewFormsList';

const ReviewFormsPage = () => {
  return (
    <AuthWrapper>
      <Head title="Review Forms" />
      <Layout
        section="review"
        breadcrumbs={[{ link: '/', label: 'Home' }, { label: 'Review forms' }]}
      >
        <ReviewFormsList />
      </Layout>
    </AuthWrapper>
  );
};

export default ReviewFormsPage;
