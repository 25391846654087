import { useContext } from 'react';
import { FormsContext } from './forms-context';
import { fetchAPI } from '../../utils/fetch-api';
import { getQueryVar } from '../../utils/query-vars';
import {
  IFormInReview,
  IFormTemplate,
  IWorkflow,
  ISendFormToLearner,
} from './forms-reducer';
import { TFormTemplateStatus } from './form-types';

export const useNewForm = () => {
  const { state } = useContext(FormsContext);
  return state.newForm;
};

export const useSetNewForm = () => {
  const { dispatch } = useContext(FormsContext);
  return (form: IFormTemplate) =>
    dispatch({ type: 'SET_NEW_FORM', payload: form });
};

export const useSetNewWorkflow = () => {
  const { dispatch } = useContext(FormsContext);
  return (payload: IWorkflow) => {
    dispatch({ type: 'SET_NEW_WORKFLOW', payload: payload });
  };
};

export const useClearNewWorkflow = () => {
  const { dispatch } = useContext(FormsContext);
  return () => {
    dispatch({ type: 'CLEAR_NEW_WORKFLOW' });
  };
};

export const useNewWorkflow = () => {
  const { state } = useContext(FormsContext);
  return state.newWorkflow;
};

export const useClearNewForm = () => {
  const { dispatch } = useContext(FormsContext);
  return () => dispatch({ type: 'CLEAR_NEW_FORM' });
};

export const useClearReviewingForm = () => {
  const { dispatch } = useContext(FormsContext);
  return () => dispatch({ type: 'CLEAR_REVIEWING_FORM' });
};

export const useSendFormToLearner = () => {
  const { state } = useContext(FormsContext);
  return state.sendFormToLearner;
};

export const useSetSendFormToLearner = () => {
  const { dispatch } = useContext(FormsContext);
  return (form: ISendFormToLearner) =>
    dispatch({ type: 'SET_SEND_FORM_TO_LEARNER', payload: form });
};

export const useSendFormToEmployer = () => {
  const { state } = useContext(FormsContext);
  return state.sendFormToEmployer;
};

export const useSetSendFormToEmployer = () => {
  const { dispatch } = useContext(FormsContext);
  return (form: IFormInReview) =>
    dispatch({ type: 'SET_SEND_FORM_TO_EMPLOYER', payload: form });
};

export const useSetReviewingForm = () => {
  const { dispatch } = useContext(FormsContext);
  return (form: IFormTemplate) => {
    dispatch({ type: 'SET_REVIEWING_FORM', payload: form });
  };
};

export const useReviewingForm = () => {
  const { state } = useContext(FormsContext);
  return state.approvingForm;
};

export const useEditForm = () => {
  const { state, dispatch } = useContext(FormsContext);
  const currentForm = state.newForm;
  const workflowId = getQueryVar('workflowId');
  const isUpdating = !!currentForm.id;

  const save = async (status?: TFormTemplateStatus) => {
    const payload = {
      formName: currentForm.formName,
      formData: currentForm.formData,
      workflowId,
      status,
    };
    const res = await fetchAPI<string>({
      method: isUpdating ? 'PUT' : 'POST',
      body: payload,
      path: `formtemplates/${currentForm.id || ''}`,
      responseType: 'text',
    });
    if (res.success) {
      // update IFormTemplate with id to continue editing
      if (!isUpdating) {
        dispatch({
          type: 'SET_NEW_FORM',
          payload: {
            ...currentForm,
            id: res.body,
          },
        });
      }
    }
    return res;
  };

  return save;
};
