import { PagedFormAssignment } from '../context-providers/forms/paged-form-assignment';
import { fetchAPI } from './fetch-api';

const RemindersUtil = {
  async stopReminders(formAssignment: PagedFormAssignment): Promise<void> {
    // This function will stop reminders for a given form assignment
    const response = await fetchAPI<void>({
      path: `email/stop/${formAssignment.id}`,
      method: 'PATCH',
    });

    if (!response.success) {
      throw new Error('Failed to stop reminders');
    }
  },
};

export default RemindersUtil;
