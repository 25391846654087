import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { FormAssignmentStatus } from '../../../context-providers/forms/form-assignment-status';
import { PagedFormAssignment } from '../../../context-providers/forms/paged-form-assignment';
import ReactGA from '../../../utils/google-analytics';
import { formatDate } from '../../../utils/dates';
import { usePagedRequest } from '../../../utils/paged-request';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import AssignmentStatusBadge from '../../atoms/status-badge/AssignmentStatusBadge';
import Table from '../../atoms/table/Table';
import { TableActions } from '../../atoms/table/Table.style';
import {
  IconCheck,
  IconCloseCircle,
  IconDelete,
  IconDownload,
  IconEdit,
  IconError,
  IconRefresh,
  IconShow,
  IconSignature,
} from '../../icons';
import { FullWidthContainer } from '../../layout/Layout.style';
import { SmallText } from './ReviewFormsList.style';
import configLoader from '../configuration/configuration-loader';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import { FlexGrid } from '../../atoms/custom-elements/Flex.style';
import FormNameFilter from '../../atoms/filter/filter-types/form-name-filter';
import FormStatusFilter from '../../atoms/filter/filter-types/form-status-filter';
import FormLearnerFilter from '../../atoms/filter/filter-types/form-learner-filter';
import FormEmployerFilter from '../../atoms/filter/filter-types/form-employer-filter';
import {
  deleteQueryParams,
  getAllQueryVar,
  urlWithSetMultipleQueryVar,
} from '../../../utils/query-vars';
import ActionLink from '../../atoms/custom-elements/linkaction.style';
import { FilterItem } from '../../atoms/filter/models/filter-item';
import TableFilter from '../../atoms/filter/TableFilter';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { Permission } from '../../../context-providers/enums/global-enums';
import { getUserClaims } from '../../../utils/jwt-decode';
import FormTrainingProviderFilter from '../../atoms/filter/filter-types/form-training-provider-filter';
import { useClearReviewingForm } from '../../../context-providers/forms/forms-hooks';
import { StageId } from '../../../context-providers/forms/form-workflows';
import DeleteData from '../../modals/forms/DeleteData';
import DownloadForm from '../../modals/forms/DownloadForm';
import KebabMenu, { IKebabMenuItem } from '../../atoms/kebab-menu/KebabMenu';
import IconButton from '../../atoms/icon-button/IconButton';
import RemindersUtil from '../../../utils/remindersUtil';
import StopReminders from '../../modals/forms/StopReminders';

const isBrowser = typeof window !== 'undefined';

const ReviewFormsList = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const user = getUserClaims();

  let canDeleteReviewForm: boolean = false;

  if (user) {
    canDeleteReviewForm = userHasPermission(
      user.permissions,
      Permission.DeleteReviewForm,
    );
  }

  const showModal = useShowModal();
  const config = configLoader();
  const clearReviewingForm = useClearReviewingForm();

  const [assignedToValues, setAssignedToValues] = useState<FilterItem[]>([
    { id: '1', name: 'Learner', selected: false },
    { id: '2', name: 'Employer', selected: false },
    { id: '3', name: 'Training Provider', selected: false },
  ]);

  const [assignedToLearner, setAssignedToLearner] = useState<boolean>(false);
  const [assignedToEmployer, setAssignedToEmployer] = useState<boolean>(false);
  const [assignedToTrainingProvider, setAssignedToTrainingProvider] =
    useState<boolean>(false);

  const returnedItems: FilterItem[] = [
    { id: '1', name: 'Learner', selected: false },
    { id: '2', name: 'Employer', selected: false },
    { id: '3', name: 'Training Provider', selected: false },
  ];

  const onAssignedToChanged = (assignedToOptions: FilterItem[]): void => {
    let url = '/';

    if (assignedToOptions.length === 0) {
      setAssignedToLearner(false);
      setAssignedToEmployer(false);
      setAssignedToTrainingProvider(false);
      setAssignedToValues(returnedItems);
      url = deleteQueryParams('assignedTo');
    } else {
      const assignedTo = assignedToOptions.map((index) => {
        if (index.name === 'Learner') {
          setAssignedToLearner(true);
          setAssignedToEmployer(false);
          setAssignedToTrainingProvider(false);
        } else if (index.name === 'Employer') {
          setAssignedToLearner(false);
          setAssignedToEmployer(true);
          setAssignedToTrainingProvider(false);
        } else if (index.name === 'Training Provider') {
          setAssignedToLearner(false);
          setAssignedToEmployer(false);
          setAssignedToTrainingProvider(true);
        }
        return index;
      });

      setAssignedToValues(assignedTo);

      url = urlWithSetMultipleQueryVar(
        'assignedTo',
        assignedToOptions.map((i) => i.name),
      );
    }
    navigate(url);
  };

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const formNameParams = extractQueryVars('formNames');
  const statusParams = extractQueryVars('statuses');
  const learnerParams = extractQueryVars('learners');
  const employerParams = extractQueryVars('employers');
  const trainingProviderParams = extractQueryVars('trainingProviders');
  const hasActiveFilters =
    formNameParams.length > 0 ||
    statusParams.length > 0 ||
    employerParams.length > 0 ||
    learnerParams.length > 0 ||
    trainingProviderParams.length > 0;

  const reviewForms = usePagedRequest<PagedFormAssignment>(
    'filters/formspage',
    {
      sortColumnName: 'lastModified',
      formNames: formNameParams.join(','),
      statuses: statusParams.join(','),
      learners: learnerParams.join(','),
      employers: employerParams.join(','),
      trainingProviders: trainingProviderParams.join(','),
    },
  );

  const onModalConfirmDelete = async (formAssignment: PagedFormAssignment) => {
    const res = await fetchAPI<string>({
      path: `v2/forms/delete/${formAssignment?.id}`,
      method: 'DELETE',
      responseType: 'text',
    });

    if (res.success) {
      window.location.reload();
    }
  };

  const handleReviewClick = (formAssignment: PagedFormAssignment) => {
    clearReviewingForm();
    navigate(
      `/review-${
        formAssignment.learnerFormAssignmentStatus ===
        FormAssignmentStatus.ReadyForReview
          ? 'learner'
          : 'employer'
      }-form/?fid=${formAssignment.id}`,
    );
  };

  const handleResendClick = (formAssignment: PagedFormAssignment) => {
    if (
      formAssignment.currentStageId === StageId.LEARNER &&
      (formAssignment.learnerFormAssignmentStatus ===
        FormAssignmentStatus.NULL ||
        formAssignment.learnerFormAssignmentStatus ===
          FormAssignmentStatus.NotStarted ||
        formAssignment.learnerFormAssignmentStatus ===
          FormAssignmentStatus.Started)
    ) {
      navigate(`/send-learner-form/?fid=${formAssignment.id}`);
    } else if (
      formAssignment.currentStageId === StageId.EMPLOYER &&
      (formAssignment.employerFormAssignmentStatus ===
        FormAssignmentStatus.NULL ||
        formAssignment.employerFormAssignmentStatus ===
          FormAssignmentStatus.NotStarted ||
        formAssignment.employerFormAssignmentStatus ===
          FormAssignmentStatus.Started)
    ) {
      navigate(`/send-employer-form/?fid=${formAssignment.id}`);
    } else if (
      formAssignment.learnerFormAssignmentStatus ===
      FormAssignmentStatus.Rejected
    ) {
      navigate(`/send-learner-reject-form/?fid=${formAssignment.id}`);
    } else if (
      formAssignment.employerFormAssignmentStatus ===
      FormAssignmentStatus.Rejected
    ) {
      navigate(`/send-employer-reject-form/?fid=${formAssignment.id}`);
    }
  };

  const dataMenuItems = (data: PagedFormAssignment): IKebabMenuItem[] => {
    let menuItems: IKebabMenuItem[] = [];

    let downloadItem: IKebabMenuItem = {
      name: 'Download',
      qatag: `forms-btn-download-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      iconLeft: <IconDownload />,
      srOnly: <span className="sr-only">Download {data.formName} form</span>,
      onClick: () => {
        showModal(DownloadForm, {
          formId: data.id,
        });
      },
    };

    let viewItem: IKebabMenuItem = {
      name: 'View',
      qatag: `forms-btn-view-completed-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      iconLeft: <IconShow />,
      srOnly: <span className="sr-only">View</span>,
      onClick: () => {
        navigate(`/review-completed-form/?fid=${data.id}`);
      },
    };

    let deleteItem: IKebabMenuItem = {
      name: 'Delete',
      qatag: `forms-btn-delete-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      iconLeft: <IconDelete fill={!canDeleteReviewForm ? '#ccc' : ''} />,
      srOnly: <span className="sr-only">Delete {data.formName} form</span>,
      disabled: !canDeleteReviewForm,
      onClick: () => {
        showModal(DeleteData, {
          onConfirmDelete: () => {
            onModalConfirmDelete(data);
          },
        });
      },
    };

    let reviewItem: IKebabMenuItem = {
      name: 'Review',
      qatag: `forms-btn-review-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      iconLeft: <IconSignature />,
      srOnly: <span className="sr-only">Review</span>,
      onClick: () => {
        handleReviewClick(data);
      },
    };

    let continueItem: IKebabMenuItem = {
      name: 'Continue',
      qatag: `forms-btn-continue-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      iconLeft: <IconEdit />,
      srOnly: <span className="sr-only">Continue</span>,
      onClick: () => {
        navigate(`/customer-form/?fid=${data.id}`);
      },
    };

    let resendItem: IKebabMenuItem = {
      name: 'Resend',
      qatag: `forms-btn-resend-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      iconLeft: <IconRefresh />,
      srOnly: <span className="sr-only">Resend</span>,
      onClick: () => {
        handleResendClick(data);
      },
    };

    let stopRemindersItem: IKebabMenuItem = {
      name: 'Stop reminders',
      qatag: `forms-btn-stop-reminders-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      iconLeft: <IconCloseCircle />,
      srOnly: <span className="sr-only">Stop reminders</span>,
      onClick: () => {
        showModal(StopReminders, {
          formAssignment: data,
          onConfirmStop: () => {
            RemindersUtil.stopReminders(data).then(() => {
              window.location.reload();
            });
          },
        });
      },
    };

    if (
      data.overallAssignmentStatus === FormAssignmentStatus.NotStarted &&
      data.currentStageId === StageId.PROVIDER
    ) {
      menuItems.push(continueItem);
    } else if (
      data.learnerFormAssignmentStatus ===
        FormAssignmentStatus.ReadyForReview ||
      data.employerFormAssignmentStatus === FormAssignmentStatus.ReadyForReview
    ) {
      menuItems.push(reviewItem);
    } else if (
      data.overallAssignmentStatus === FormAssignmentStatus.Started ||
      data.overallAssignmentStatus === FormAssignmentStatus.Rejected ||
      data.overallAssignmentStatus === FormAssignmentStatus.NotStarted
    ) {
      menuItems.push(resendItem);
    }

    menuItems.push(viewItem);

    if (data.overallAssignmentStatus === FormAssignmentStatus.Completed) {
      menuItems.push(downloadItem);
    }

    if (
      data.hasEmailReminder &&
      (data.overallAssignmentStatus === FormAssignmentStatus.Started ||
        data.overallAssignmentStatus === FormAssignmentStatus.Rejected ||
        data.overallAssignmentStatus === FormAssignmentStatus.NotStarted)
    ) {
      menuItems.push(stopRemindersItem);
    }

    if (
      data.overallAssignmentStatus === FormAssignmentStatus.NotStarted &&
      (data.learnerFormAssignmentStatus === FormAssignmentStatus.NULL ||
        data.learnerFormAssignmentStatus === FormAssignmentStatus.NotStarted) &&
      (data.trainingProviderFormAssignmentStatus ===
        FormAssignmentStatus.NULL ||
        data.trainingProviderFormAssignmentStatus ===
          FormAssignmentStatus.NotStarted) &&
      (data.employerFormAssignmentStatus === FormAssignmentStatus.NULL ||
        data.employerFormAssignmentStatus === FormAssignmentStatus.NotStarted)
    ) {
      menuItems.push(deleteItem);
    }

    return menuItems;
  };

  const renderRow = (data: PagedFormAssignment) => [
    data.formName,
    <Stack stackMultiplier={1.5} key="1">
      {data.trainingProviderName && (
        <div>
          <p>{data.trainingProviderName}</p>
          <SmallText>Training provider</SmallText>
        </div>
      )}
      <div>
        <p>{data.learnerName}</p>
        {data.learnerName && <SmallText>Learner</SmallText>}
      </div>
      {data.employerName && (
        <div>
          <p>{data.employerName}</p>
          <SmallText>Employer</SmallText>
        </div>
      )}
    </Stack>,
    <Stack stackMultiplier={3} key="2">
      {data.trainingProviderName && (
        <div>
          {data.trainingProviderDateSent
            ? formatDate(data.trainingProviderDateSent)
            : ''}
        </div>
      )}
      {data.learnerName && (
        <div>
          {data.learnerDateSent ? formatDate(data.learnerDateSent) : ''}
        </div>
      )}
      {data.employerName && (
        <div>
          {data.employerDateSent ? formatDate(data.employerDateSent) : ''}
        </div>
      )}
    </Stack>,
    <Stack stackMultiplier={2.5} key="3">
      {data.trainingProviderName && (
        <div data-qa={`reviewStatus-provider-${data.formName}`}>
          <AssignmentStatusBadge
            status={data.trainingProviderFormAssignmentStatus}
          />
        </div>
      )}
      {data.learnerName && (
        <div data-qa={`reviewStatus-learner-${data.formName}`}>
          <AssignmentStatusBadge status={data.learnerFormAssignmentStatus} />
        </div>
      )}
      {data.employerName && (
        <div data-qa={`reviewStatus-employer-${data.formName}`}>
          <AssignmentStatusBadge status={data.employerFormAssignmentStatus} />
        </div>
      )}
    </Stack>,
    <Stack stackMultiplier={3} key="4">
      {data.hasEmailReminder && (
        <IconButton
          variant="reminder"
          icon={<IconCheck responsive />}
        ></IconButton>
      )}
      {!data.hasEmailReminder && <div> </div>}
    </Stack>,
    <Stack stackMultiplier={3} key="5">
      {data?.trainingProviderName && (
        <div>
          {data.trainingProviderDateCompleted
            ? formatDate(data.trainingProviderDateCompleted)
            : ' '}
        </div>
      )}
      {data?.learnerName && <div>{formatDate(data.learnerDateCompleted)}</div>}
      {data?.employerName && (
        <div>
          {data.employerDateCompleted
            ? formatDate(data.employerDateCompleted)
            : ' '}
        </div>
      )}
    </Stack>,
    <TableActions key="5">
      <KebabMenu items={dataMenuItems(data)} canCreateForm={false}></KebabMenu>
    </TableActions>,
  ];

  const clearAllFilters = () => {
    setAssignedToValues(returnedItems);
    const url = deleteQueryParams(
      'formNames',
      'statuses',
      'assignedTo',
      'learners',
      'employers',
      'page',
      'trainingProviders',
    );
    navigate(url || '');
  };

  return (
    <FullWidthContainer>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={1}>
          <h1>Review and sign forms</h1>
          <p>You can review or sign any active forms.</p>
        </Stack>
        <FlexGrid column={2} row={1}>
          <FormNameFilter />
          <FormStatusFilter />
          <TableFilter
            filterName="assignedTo"
            items={assignedToValues}
            title="Assigned to"
            disabled={false}
            onItemChanged={onAssignedToChanged}
          />
          {assignedToLearner && <FormLearnerFilter />}
          {assignedToEmployer && <FormEmployerFilter />}
          {assignedToTrainingProvider && <FormTrainingProviderFilter />}
          {hasActiveFilters && (
            <ActionLink onClick={clearAllFilters}>Clear all</ActionLink>
          )}
        </FlexGrid>
        <Table
          caption="List of forms to review"
          data={reviewForms.data}
          headerItems={[
            { sortColumnName: 'formName', label: 'Form name' },
            { sortColumnName: 'learnerName', label: 'Assigned To' },
            { sortColumnName: 'lastModified', label: 'Date sent' },
            { sortColumnName: 'status', label: 'Status' },
            { sortColumnName: 'reminders', label: 'Reminders' },
            { sortColumnName: 'dateCompleted', label: 'Date complete' },
            { label: '' },
          ]}
          renderRow={renderRow}
          paged={reviewForms.pagedParams}
        />
        <Paging pageCount={reviewForms.pageCount} />
      </Stack>
    </FullWidthContainer>
  );
};

export default ReviewFormsList;
